<template>
  <div>
    <div class="utils__title mb-3">
      <strong class="text-uppercase font-size-16">車両一覧</strong>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-header">
            <div class="pull-right mt-2">
              <router-link :to="{ name: 'vehicleCreateBulk' }">
                <a-button type="primary" class="ml-3" :class="$style.filledBtn" autoInsertSpaceInButton="false">一括作成</a-button>
              </router-link>
            </div>
            <h2>車両一覧</h2>
          </div>
          <div class="card-body">
            <div v-if="driveLoading != 2" class="text-center">
              <a-spin tip="Loading..." />
            </div>
            <div v-if="driveLoading == 2">
              <div>
                <span class="mr-5">フィルター</span>
                <a-form layout="inline">
                  <a-form-item label="車両名">
                    <a-auto-complete
                      :data-source="autocompleteData"
                      style="width: 200px"
                      placeholder="車両名"
                      :filter-option="filterOption"
                      @select="searchActionName"
                      @search="searchActionName"
                    />
                  </a-form-item>
                  <a-form-item label="車両番号">
                    <a-auto-complete
                      :data-source="autocompleteDataNumber"
                      style="width: 200px"
                      placeholder="車両番号"
                      :filter-option="filterOption"
                      @select="searchActionNumber"
                      @search="searchActionNumber"
                    />
                  </a-form-item>
                  <a-form-item label="支店名">
                    <a-select
                      mode="multiple"
                      placeholder="支店名"
                      :value="selectedBranches"
                      style="min-width: 300px"
                      @change="handleBranchChange"
                    >
                      <a-select-option v-for="item in filteredBranchOptions" :key="item.name" :value="item.name">
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-form>
                <hr style="margin: 20px 0 0 0">
              </div>
              <a-table
                :columns="columns"
                :data-source="filteredVehiclesData"
                rowKey="id"
                :pagination="paginationSetting"
              >
                <span slot="name" slot-scope="name, record"><span v-if="myRole === 0">{{ record.id }}: </span><router-link :to="{ name: 'vehicleShow', params: { id: record.id }}">{{ name }}</router-link></span>
              </a-table>
              <p class="pull-right">
                <a-button @click="exportCsv" style="margin-right: 10px;" :class="$style.filledBtn">CSV 出力</a-button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Vue from 'vue'
import { enumData } from '@/services/enum'

export default {
  data() {
    return {
      myRole: 3,
      driveLoading: 0,
      columns: [
        {
          title: '車両名',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' },
          sorter: (a, b) => a.name < b.name ? 1 : -1,
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: '車両番号',
          dataIndex: 'number',
          sorter: (a, b) => a.number < b.number ? 1 : -1,
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: '支店名',
          dataIndex: 'branch_name',
          defaultSortOrder: 'descend',
          sorter: (a, b) => a.branch_name < b.branch_name ? 1 : -1,
          sortDirections: ['descend', 'ascend'],
        },
      ],
      paginationSetting: {
        defaultPageSize: 30,
      },
      vehiclesData: [],
      selectedBranches: [],
      branches: [],
      searchText: '',
      searchTextNumber: '',
    }
  },
  computed: {
    hasMasterRole() { return this.myRole === 0 },
    autocompleteData() { return this.filteredVehiclesData.map((item) => { return item.name }) },
    autocompleteDataNumber() { return this.filteredVehiclesData.map((item) => { return item.number }) },
    filteredBranchOptions() { return this.branches.filter(o => !this.selectedBranches.includes(o)) },
    filteredVehiclesData() {
      let filteredData = this.vehiclesData
      if (this.searchText) filteredData = filteredData.filter(v => v.name.includes(this.searchText))
      if (this.searchTextNumber) filteredData = filteredData.filter(v => v.number.includes(this.searchTextNumber))
      if (this.selectedBranches.length) filteredData = filteredData.filter(v => this.selectedBranches.includes(v.branch_name))
      return filteredData
    },
  },
  beforeMount() {
    Vue.prototype.$api.send('get', 'user').then(myResponse => {
      this.myRole = myResponse.role
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 自身の情報取得に失敗しました。',
        })
      })
  },
  created() {
    const vehicleResult = Vue.prototype.$api.send('get', 'vehicles/list/0')
    vehicleResult.then(response => {
      this.vehiclesData = response.map((item) => {
        if (item.user) item.user_name = item.user.name
        else item.user_name = ''
        return item
      })
      this.vehiclesData.sort((a, b) => a.is_archived - b.is_archived)
      this.driveLoading++
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 車両の取得に失敗しました。',
        })
        this.driveLoading++
      })

    const branchesResult = Vue.prototype.$api.send('get', 'user/branches')
    branchesResult.then(response => {
      this.branches = response
      this.driveLoading++
    })
      .catch(error => {
        this.$notification['error']({
          message: error.status + ': 支店の取得に失敗しました。',
        })
        this.driveLoading++
      })
  },
  methods: {
    handleBranchChange(selectedBranches) { this.selectedBranches = selectedBranches },
    searchActionName(text) { this.searchText = text },
    searchActionNumber(text) { this.searchTextNumber = text },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toUpperCase().indexOf(input.toUpperCase()) >= 0
      )
    },
    exportCsv() {
      const isArchivedStr = ['-', '無効化']
      let csv = '\ufeff'
      csv += 'id,車両名,車両番号,支店,車種,メーカー,型式,リース満了日,車検満了日,次回法定点検日,任意保険満了日,無効化\n'
      this.vehiclesData.forEach(v => {
        const carType = enumData.carType.find(item => item.value === v['car_type'])
        const carTypeStr = carType ? carType.label : ''
        csv += v['id'] + ',' + v['name'] + ',' + v['number'] + ',' + v['branch_name'] + ',' + carTypeStr + ',' + this.outputStrOrNull(v['manufacturer']) + ',' + this.outputStrOrNull(v['model']) + ',' + this.outputStrOrNull(v['lease_expiration_date']) + ',' + this.outputStrOrNull(v['inspection_expiration_date']) + ',' + this.outputStrOrNull(v['legal_inspection_date']) + ',' + this.outputStrOrNull(v['insurance_expiration_date']) + ',' + isArchivedStr[v['is_archived']] + '\n'
      })
      var blob = new Blob([csv], { type: 'text/plain' })
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'vehicles.csv'
      link.click()
    },
    outputStrOrNull(str) {
      if (str === null) return ''
      return str
    },
  },
}
</script>

<style lang="scss" module>
@import "./style.module.scss";

.list-name {
  font-size: 1.5rem;
}

.highlight {
  background-color: rgb(255, 192, 105);
  padding: 0px;
}

</style>
